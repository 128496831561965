var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _c("v-spacer"),
          _c("v-switch", {
            staticClass: "text-uppercase text-subtitle-1",
            attrs: { label: "edit", color: _vm.color },
            on: {
              change: function($event) {
                return _vm.$emit("update:edit", $event)
              }
            },
            model: {
              value: _vm.editModel,
              callback: function($$v) {
                _vm.editModel = $$v
              },
              expression: "editModel"
            }
          })
        ],
        1
      ),
      _c(
        "v-slide-x-transition",
        [
          _vm.edit
            ? _c(_vm.editComponent, {
                tag: "component",
                attrs: { data: _vm.data },
                on: {
                  close: function($event) {
                    _vm.editModel = false
                    _vm.$emit("update:edit", _vm.editModel)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }