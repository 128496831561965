<template>
    <div>
        <div class="d-flex align-center">
            <h3>{{ title }}</h3>
            <v-spacer></v-spacer>
            <v-switch
                label="edit"
                class="text-uppercase text-subtitle-1"
                v-model="editModel"
                @change="$emit('update:edit', $event)"
                :color="color"
            ></v-switch>
        </div>
        <v-slide-x-transition>
            <component
                :is="editComponent"
                :data="data"
                v-if="edit"
                @close="
                    editModel = false;
                    $emit('update:edit', editModel);
                "
            ></component>
        </v-slide-x-transition>
    </div>
</template>
<script>
export default {
    name: "rental-employment-edit",
    components: {
        RentalAddressEdit: () => import("../RentalAddress/RentalAddressEdit"),
        EmploymentEmployerEdit: () =>
            import("../Employer/EmploymentEmployerEdit"),
    },
    props: {
        edit: {
            type: Boolean,
            required: false,
            default: false,
        },
        editComponent: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        title: {
            type: String,
            required: true,
        },
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            editModel: this.edit,
        };
    },
};
</script>

<style scoped>
</style>